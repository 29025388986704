import React from "react"
import Layout from "../components/Layout"
import ProfileImages from "../components/ProfileImages"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"
import video from "../assets/videos/mumbai-call-girl.mp4"
import LocationButton from "../components/LocationButton"
import { Link } from "gatsby"
import GalleryTwo from "../components/GalleryTwo"

const Mumbaicallgirls = () => {
  return (
    <Layout>
      <SEO
        title="Mumbai Call Girls | Independent Call girl service | Erotic Fiction"
        description="Call girls service nearby your location, get a chance to experience the erotic moment with our hot & attractive Independent call girl Mumbai."
      />

      <Wrapper>
        <div className="top_banner_new">
          <StaticImage
              src="../assets/images/call-girls.jpg"
              alt="Mumbai call girls"
              placeholder="blurred"
              layout="constrained"
              className="top_img"
            />
          </div>
      {/* video start  */}
        <div className="video_box">
          <div className="video_left">
            <StaticImage
              src="../assets/images/quote2.jpg"
              alt="cheap escorts in mumbai"
              placeholder="blurred"
              layout="constrained"
              className="video_img"
            />
          </div>
          <div className="video_wrapper">
            <video muted loop={true} autoPlay="autoplay">
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
        {/* video end  */}
        <div className="home_top">
          {/* slide line start  */}
          <div className="section-title">
            {/* <Breadcrumb location={location} crumbLabel="Mumbai call girls" /> */}
            <h1 className="section_title_main">
              WELCOME TO MUMBAI CALL GIRL GALLARY
            </h1>
            <div className="bar"></div>
          </div>
          {/* slide line start  */}
          <p className="text_left">
            At <Link to="/mumbai-call-girls" className="a_color"> Mumbai Call Girls </Link> offer the highest quality adult
            companionship to discerning gentlemen in India with our vetted
            selection of gorgeous girls who are more than happy to indulge your
            wildest fantasies! The pleasure of a night with one of our beautiful
            escorts is something that you will want to experience for yourself.
          </p>

          <p className="text_left">
            We guarantee an unforgettable evening, and we can assure you the
            best possible service because it's what makes us believe in
            ourselves. You're not just paying for sex - there are plenty of
            other places where you buy your time alone with someone-you're
            paying to be treated like royalty!
          </p>

          <p className="text_left">
           <Link to="/mumbai-call-girls" className="a_color"> Call Girls in Mumbai </Link> prefers customer desire. Our service is
            affordable because of this. The client has only hot call girls in
            Mumbai at our place for enjoying with comfortability. You can also
            enjoy the service in the hotel where the clients are staying.
          </p>
        </div>

       {/* location btn component start */}
          <LocationButton />
        {/* location btn component end */}

        {/* about sec start  */}
        <div className="mini_container">
          {/* slide line start  */}
          <div className="section-title">
            <h2 className="cat_h2">Escort Service Mumbai</h2>
            <div className="bar"></div>
          </div>
          {/* slide line start  */}
        </div>

        {/* <div>
          <section className="section2" id="AboutUs">
            <table>
              <tbody>
                <tr>
                  <td>
                    <StaticImage
                      src="https://images.unsplash.com/photo-1567892320421-1c657571ea4a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDZ8fHNleHklMjBnaXJsc3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                      alt="mumbai escorts"
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </td>
                  <td>
                    <h2 className="title new_head_style">About Us</h2>
                    <span className="sub-title">
                      Lorem ipsum dolor sit amet, consectetur adi
                    </span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <a href="#OurMission" className="btn1">
                      Our Misssion
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section className="section3" id="OurMission">
            <table>
              <tbody>
                <tr>
                  <td>
                    <h2 className="title new_head_style">About Us</h2>
                    <span className="sub-title">
                      Lorem ipsum dolor sit amet, consectetur adi
                    </span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <a href="#OurMission" className="btn1">
                      Our Misssion
                    </a>
                  </td>
                  <td>
                    <StaticImage
                      src="https://images.unsplash.com/photo-1574539602047-548bf9557352?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fHNleHklMjBnaXJsc3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                      alt="mumbai escorts"
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div> */}

        {/* new sec  */}
        <div className="section about">
          <div className="section-center about-center">
            <article className="about-img">
              <StaticImage
                src="../assets/images/sexy-callgirl-one.jpg"
                alt="mumbai escorts"
                placeholder="blurred"
                layout="constrained"
                className="hero-photo"
              />
            </article>
            <article className="about-info">
              <div className="section-title about-title">
                <h2 className="cat_h2">About Our Hottest Mumbai Call Girls </h2>
                <div className="underline" />
              </div>
              <p className="text_left">
                Our call girls Mumbai are diverse and choose from a wide range of
                personalities, allowing customers to stay up all night with
                their choice. Our <Link className="a_color" to="/"> Mumbai escort services </Link> can be booked online
                through the company's website. We provide a variety of photos of
                call girls on our website for the customer's view.
              </p>

              <p className="text_left">
                By choosing our <Link className="a_color" to="/mumbai-call-girls">call girls in Mumbai</Link>, you can option without a shaking pocket
                of high-quality service. We provide the hottest babes for
                customer satisfaction and fulfill their needs. Without any
                hidden fees, we provide our service at your doorstep.
              </p>
             <Link to="/escorts/aarna" className="btn">
                about me
              </Link>
            </article>
          </div>
        </div>

        {/* about sec end  */}


           

           <div className="home_top">
          {/* slide line start  */}
          <div className="section-title">
            {/* <Breadcrumb location={location} crumbLabel="Mumbai call girls" /> */}
            <h2 className="section_title_main">
              Book Mumbai Call Girls for the Night
            </h2>
          </div>
          {/* slide line start  */}
          <p className="text_left">
           <Link className="a_color" to="/mumbai-call-girls">Call girls in Mumbai</Link> are the first option when you search online to know more about high class call girls in Mumbai, they are those kind of girls who can provide you the best service of your life if you choose them as your partner at the time of making love. So if you are searching online to get call girls in Mumbai at cheap rate then your search ends here, as we have all types of call girls available on our website like,
          </p>
          <ul className="home_ul">
              <li>independent Mumbai call girl,</li>
              <li>model Mumbai call girl,</li>
              <li>air hostess Mumbai call girl,</li>
              <li>and housewife Mumbai call girl etc.</li>
            </ul>

          <h2 className="section_title_main">
            We Provide 100% Vaccinated call girls in Mumbai
            </h2>
            <p>Every girl is vaccinated in order to make sure that they are free from any sexually transmitted diseases. There’s a high chance of contracting such diseases if you aren’t on top of your game with your health and personal safety. Our <Link className="a_color" to="/mumbai-call-girls">Mumbai call girl</Link> take care of both, staying healthy themselves as well as keeping you safe.</p>

            <p>A thorough screening process is conducted with every girl to ensure that she’s 100% healthy and safe. Once you’ve hired one of our girls, you can sleep easy knowing that there isn’t a risk of contracting any sexually transmitted diseases. Don’t settle for anything less than your health and safety. These <Link className="a_color" to="/mumbai-call-girls">vaccinated call girls in Mumbai</Link> will give erotic fantasies that you will not forget in your lifetime.</p>

            <h3 className="section_title_main">
             How can I trust Vaccinated call girl or not?
            </h3>

            <p>These days, it has become very common to find girls working in massage parlors who are infected with some sexually transmitted diseases. These infections can be transferred easily if they are not vaccinated, putting you at risk of contracting them as well. This will not only lead to financial losses but also health risks. Our <b>high-class call girls in Mumbai</b> have been thoroughly screened so that you don’t have to worry about anything like that.</p>

            <h2 className="section_title_main">
             Mumbai call girls Contact Numbers for phone sex service
            </h2>

            <p>One might not have time to meet <b>call girls in Mumbai</b>, or it might be difficult to find them. However, phone sex services can help you get your share of erotic pleasure without any effort on your part. The fact that you can book a call girl in Mumbai without leaving your bed also makes it more interesting and convenient to hire them.</p>

            <p>You can also talk dirty to these girls, or have phone sex with them if that’s what you prefer. You can also meet these girls in hotels, or even get them over at your place on short notice. It’s all up to you, which makes it a lot easier to spend time with a girl of your choice.</p>

            <GalleryTwo />

            <h2 className="section_title_main">
            Hire Rs.5000 Call girls In Navi Mumbai
            </h2>

            <p>One thing that most people don’t realize is that hiring a high-end escort can actually be quite affordable. When you consider that a few hours of company from an escort may cost more than double what you might pay to see a <Link to="/mumbai-call-girls" className="a_color"> Navi Mumbai call girls </Link>, it seems like a no-brainer. The good news is that there are escorts who work on short notice and will visit your place as soon as possible at cheap rates.</p>

            <p>The market is flooded with call girls who are willing to provide their services at very cheap rates. You can find a girl who is ready to visit you in just a couple of hours. Once you have decided that you need to book escorts in Mumbai.</p>

            <h2 className="section_title_main">
           Mumbai call girls Whats app Number for friendship
            </h2>

            <p>Get in touch with one of our most beautiful and charming girls, who are available for bookings around-the-clock. With just a single call you can start getting acquainted with our sensational features such as: </p>
              <ul className="home_ul_cat">
                <li> <Link to="/category/young-escorts">Young Escorts services</Link> </li>
                <li> <Link to="/category/threesome">Threesome</Link> </li>
                <li> <Link to="/category/women-seeking-men">Women Seeking Men</Link> </li>
                <li> <Link to="/category/strip-club">Strip Club</Link> </li>
                <li> <Link to="/category/party-girls">Party Girls</Link> </li>
                <li> <Link to="/category/one-night-stand">One Night Stand</Link> </li>
                <li> <Link to="/category/happy-hour">Happy Hour</Link> </li>
                <li> <Link to="/category/girlfriend-experience">Girlfriend experience</Link> </li>
              </ul>
              
    
             <p>Here we want you to get more than your needs. The number is also available via WhatsApp 24/7 hours! We ensure 100% privacy and confidentiality is maintained while doing business with us.</p>

            <p>If you want to book one of our babes, we will first make sure to find one that meets your standards. Our experts personally know each and every girl in their portfolio and ensure that only highly skilled companions are offered on our website.</p>

            <p>These girls are also accompanied by verified pictures, along with a detailed description of their services and availability. We value your privacy as much as you do, which is why everything is done privately. Keep in mind that photos shown may be altered to protect identity.</p>


        </div>






        <ProfileImages />
      </Wrapper>
    </Layout>
  )
}
const Wrapper = styled.div`
.home_ul li{
  color: orange;
  text-align: left;
}
.cat_h2{
  text-align: center;
}
p{
  text-align: left;
}

h2,h3{
  text-align: left;
}

.home_ul_cat li{
  text-align: left !important;
  list-style-position: inside;
}
.home_ul_cat li a{
  color: gold;
}

  .video_img {
    box-shadow: 0 0 4px 4px #ecd113cc;
  }
  /* about sec start */
  /* about sec end */
  /* .review_heading {
    text-align: center;
    text-transform: uppercase;
    margin-top: 40px;
    color: #bbaf08 !important;
    font-size: 1.8em;
  }
  .title {
    font-size: 2em;
    display: block;
  }

  .sub-title {
    font-size: 0.8em;
    color: gray;
    display: block;
  }

  .btn1 {
    padding: 10px 20px;
    background: transparent;
    border: 1px solid #8c0c3c;
    color: #8c0c3c;
    border-radius: 3px;
    outline: none;
    transition: 0.5s;
  }

  .btn1:hover {
    background: #8c0c3c;
    color: #fff;
  }

  .btn2 {
    padding: 10px 20px;
    border-radius: 3px;
    background: #1e1e1e;
    border: 1px solid #1e1e1e;
    color: #fff;
    outline: none;
    transition: 0.5s;
  }

  .btn2:hover {
    background: transparent;
    color: #1e1e1e;
  }

  .title:before {
    font-family: "FontAwesome";
    font-size: 30px;
  }

  a {
    text-decoration: none;
  }

  .section2,
  .section3 {
    width: 90%;
    margin: 5% 0%;
    margin-left: auto;
    margin-right: auto;
  }

  .section2 table,
  .section3 table {
    width: 100%;
  }

  .section2 table td,
  .section3 table td {
    width: 50%;
  }

  .section2 table td img,
  .section3 table td img {
    width: 80%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 6px 16px -6px rgba(1, 1, 1, 0.5);
  }

  .section3 table td img {
    float: right;
  }

  @media (max-width: 720px) {
    .section2 table td,
    .section3 table td {
      width: 100%;
      display: block;
    }
    .section3 table td img {
      float: none;
      margin-top: 4%;
    }
    .section2 table td img,
    .section3 table td img {
      width: 100%;
      height: 60%;
    }
  }

  @media (max-width: 300px) {
    .section2,
    .section3 {
      font-size: 12px;
    }
    .section3 table td img {
      float: none;
      margin-top: 8%;
    }
    .btn1,
    .btn2 {
      padding: 5px 10px;
    }
  } */

  /* about sec end */
  .home_top {
    width: 90vw;
    margin: 0px auto;
    text-align: center;
  }
  .home_top > h1 {
    color: #bbaf08;
    font-size: 1.8rem;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .home_top > h2 {
    color: red;
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  /* new sec  */
`

export default Mumbaicallgirls
